import Dashboard from "views/Dashboard/Dashboard.js";
import ConsultantDashboard from "views/Dashboard/ConsultantDashboard";

//Consultant Module
import ConsultantTabForm from "pages/ConsultantModule/FormTab";
import ConsultantForm from "pages/ConsultantModule/Consultant/Form";
import ConsultantList from "pages/ConsultantModule/Consultant/List";

import WorkExperienceForm from "pages/ConsultantModule/WorkExperience/Form";
import WorkExperienceList from "pages/ConsultantModule/WorkExperience/List";

import SearchlogList from "pages/ConsultantModule/SearchLog/List";

import RateCardForm from "pages/ConsultantModule/RateCard/Form";
import RateCardList from "pages/ConsultantModule/RateCard/List";

import ConsultantSchedulingForm from "pages/ConsultantModule/ConsultantScheduling/Form";
import ConsultantSchedulingList from "pages/ConsultantModule/ConsultantScheduling/List";

import ConsultantSchedulingDailyForm from "pages/ConsultantModule/ConsultantSchedulingDaily/Form";
import ConsultantSchedulingDailyList from "pages/ConsultantModule/ConsultantSchedulingDaily/List";

// import ComplaintsForm from "pages/ConsultantModule/Complaints/Form";
import ComplaintsList from "pages/ConsultantModule/Complaints/List";

import ReviewsForm from "pages/ConsultantModule/Reviews/Form";
import ReviewsList from "pages/ConsultantModule/Reviews/List";

import ChatHistoryList from "pages/ConsultantModule/ChatHistory/List";
import ChatHistoryForm from "pages/ConsultantModule/ChatHistory/Form";

import ConsultantPayoutHistoryList from "pages/ConsultantModule/ConsultantPayoutHistory/List";
import ConsultantPayoutHistoryForm from "pages/ConsultantModule/ConsultantPayoutHistory/Form";

import FreeAddonsList from "pages/ConsultantModule/FreeAddonsForBooking/List";
import FreeAddonsForm from "pages/ConsultantModule/FreeAddonsForBooking/Form";

import PastBookingList from "pages/ConsultantModule/PastBooking/List";
import PastBookingForm from "pages/ConsultantModule/PastBooking/Form";

import RefundRequestList from "pages/ConsultantModule/RefundRequest/List";
import RefundRequestForm from "pages/ConsultantModule/RefundRequest/Form";

import ReportsList from "pages/ConsultantModule/Reports/List";
import ReportsForm from "pages/ConsultantModule/Reports/Form";

import BookingReportsList from "pages/ConsultantModule/BookingReports/List";

import ConsultantReportsForm from "pages/ConsultantModule/ConsultantReports/Form";
import ConsultantReportsList from "pages/ConsultantModule/ConsultantReports/List";

import ConsultantPayoutReportsForm from "pages/ConsultantModule/ConsultantPayoutReports/Form";
import ConsultantPayoutReportsList from "pages/ConsultantModule/ConsultantPayoutReports/List";

import VisitorReportsForm from "pages/ConsultantModule/VisitorReports/Form";
import VisitorReportsList from "pages/ConsultantModule/VisitorReports/List";

import VisitorCreditReportsForm from "pages/ConsultantModule/VisitorCreditReports/Form";
import VisitorCreditReportsList from "pages/ConsultantModule/VisitorCreditReports/List";

import VisitorCreditPurchaseReportsList from "pages/ConsultantModule/VisitorCreditPurchaseReports/List";

import BookingList from "pages/ConsultantModule/Booking/List";
import BookingForm from "pages/ConsultantModule/Booking/Form";

import UpcomingBookingList from "pages/ConsultantModule/UpcomingBooking/List";
import UpcomingBookingForm from "pages/ConsultantModule/UpcomingBooking/Form";

import VisitorCreditHistoryList from "pages/ConsultantModule/VisitorCreditHistory/List";
import VisitorCreditHistoryForm from "pages/ConsultantModule/VisitorCreditHistory/Form";

import VisitorCreditPurchaseList from "pages/ConsultantModule/VisitorCreditPurchase/List";
import VisitorCreditPurchaseForm from "pages/ConsultantModule/VisitorCreditPurchase/Form";

import BookingLogsList from "pages/ConsultantModule/BookingLogs/List";
// import BookingLogsForm from "pages/ConsultantModule/BookingLogs/Form";

import BookingTransaction from "pages/ConsultantModule/BookingTransaction/List";

// FAQ Module
import FAQ from "pages/FAQModule/FAQCategory/List.js";
import FAQForm from "pages/FAQModule/FAQCategory/Form.js";
import FAQS from "pages/FAQModule/FAQ/List.js";
import FAQs from "pages/FAQModule/FAQ/Form.js";

// Blog Module
// import BlogCategory from "pages/BlogModule/BlogCategory/List.js";
// import BlogCategoryForm from "pages/BlogModule/BlogCategory/Form.js";
// import BlogLists from "pages/BlogModule/Blog/List.js";
// import BlogListForm from "pages/BlogModule/Blog/Form.js";

// User Module
import PermissionGroupList from "pages/UserModule/PermissionGroups/List.js";
import PermissionGroupForm from "pages/UserModule/PermissionGroups/Form.js";

import PermissionList from "pages/UserModule/Permissions/List.js";
import PermissionForm from "pages/UserModule/Permissions/Form.js";

import RolesList from "pages/UserModule/Roles/List.js";
import RoleForm from "pages/UserModule/Roles/Form.js";

import UsersList from "pages/UserModule/User/List.js";
import UserForm from "pages/UserModule/User/Form.js";
import ProfileForm from "pages/UserModule/User/ProfileForm.js";
import ConsultantProfileForm from "pages/UserModule/User/ConsultantProfileForm.js";

// Location Module
import CountriesList from "pages/LocationModule/Countries/List.js";
import CountryForm from "pages/LocationModule/Countries/Form.js";

import TimeZoneList from "pages/LocationModule/TimeZone/List.js";
import TimeZoneForm from "pages/LocationModule/TimeZone/Form.js";

import CountryGroupList from "pages/LocationModule/CountryGroup/List.js";
import CountryGroupForm from "pages/LocationModule/CountryGroup/Form.js";
import IndustryList from "pages/ProductModule/Industries/List.js";
import IndustryForm from "pages/ProductModule/Industries/Form.js";

// Master Module
import RssList from "pages/MasterModule/RSS/List.js";
import RssForm from "pages/MasterModule/RSS/Form.js";

import SkillsList from "pages/MasterModule/Skills/List";
import SkillsForm from "pages/MasterModule/Skills/Form";

import CurrencyList from "pages/MasterModule/Currency/List.js";
import CurrencyForm from "pages/MasterModule/Currency/Form.js";

import PersonaList from "pages/MasterModule/Personas/List.js";
import PersonaForm from "pages/MasterModule/Personas/Form.js";

// Service Module
import ServiceList from "pages/ServiceModule/Service/List.js";
import ServiceForm from "pages/ServiceModule/Service/Form.js";
import ServiceTabForm from "pages/ServiceModule/FormTab";

// Service Request
import RequestList from "pages/ServiceModule/Request/List.js";
import RequestForm from "pages/ServiceModule/Request/Form.js";

// Scoping Call
import ScopingCallList from "pages/ServiceModule/ScopingCall/List.js";
import ScopingCallForm from "pages/ServiceModule/ScopingCall/Form.js";

// Visitor Module
import VisitorGroupList from "pages/VisitorModule/VisitorGroups/List.js";
import VisitorGroupForm from "pages/VisitorModule/VisitorGroups/Form.js";
import VisitorList from "pages/VisitorModule/Visitors/List.js";
import VisitorForm from "pages/VisitorModule/Visitors/Form.js";
import IPLogList from "pages/VisitorModule/IPs/List.js";
import APILogList from "pages/VisitorModule/APIs/List.js";
import VisitorLoginLogList from "pages/VisitorModule/Visitors/LoginList";

// Page Module
import PageList from "pages/LocationModule/Pages/List.js";
import PageForm from "pages/LocationModule/Pages/Form.js";

// import { BlogCategory } from "pages/BlogModule/BlogCategory/List";
import GuestList from "pages/VisitorModule/Visitors/GuestList";

//Setting Module
import CronList from "pages/SettingModule/Cron/List.js";
import CronForm from "pages/SettingModule/Cron/Form.js";
import CronLogsList from "pages/SettingModule/Cron/Logs.js";
import ConfigList from "pages/SettingModule/Config/List.js";
import ConfigForm from "pages/SettingModule/Config/Form.js";
import AnomalyList from "pages/SettingModule/Anomaly/List.js";
import AnomalyForm from "pages/SettingModule/Anomaly/Form.js";
import NubelaLogList from "pages/SettingModule/Nubela/Logs.js";
import SignalHireLogList from "pages/SettingModule/SignalHire/Logs.js";

import { PermissionHelper, UserHelper } from "_helpers";

var dashRoutes = [];

// Dashboard
dashRoutes.push({
  path: "/dashboard",
  component: Dashboard,
  layout: "/admin",
});

dashRoutes.push({
  path: "/consultant_dashboard",
  component: ConsultantDashboard,
  layout: "/admin",
});

//Consultant routes
if (PermissionHelper.checkPermission("view_consultant_manager")) {
  dashRoutes.push({
    path: "/dashboard",
    component: ConsultantDashboard,
    layout: "/consultant",
  });
  if (PermissionHelper.checkPermission("view_consultant")) {
    dashRoutes.push(
      dashRoutes.push({
        path: "/consultant",
        component: ConsultantList,
        layout: "/admin",
      })
    );
    if (
      PermissionHelper.checkPermission("add_consultant") ||
      PermissionHelper.checkPermission("edit_consultant")
    ) {
      dashRoutes.push(
        {
          path: "/consultant-form/:id",
          component: ConsultantTabForm,
          layout: "/admin",
        },
        {
          path: "/basic-info",
          component: ConsultantForm,
          layout: "/admin/consultant-form/:id",
        }
      );
    }
  }
  if (PermissionHelper.checkPermission("view_work_experience")) {
    if (UserHelper.isConsultant() && !UserHelper.isServiceProvider()) {
      dashRoutes.push({
        path: "/work_experience",
        component: WorkExperienceList,
        layout: "/consultant",
      });
    }
    dashRoutes.push({
      path: "/work-experience",
      component: WorkExperienceList,
      layout: "/admin/consultant-form/:id",
    });
    if (
      PermissionHelper.checkPermission("add_work_experience") ||
      PermissionHelper.checkPermission("edit_work_experience")
    ) {
      if (UserHelper.isConsultant()) {
        dashRoutes.push({
          path: "/work-experience-form/:childId",
          component: WorkExperienceForm,
          layout: "/consultant",
        });
      } else {
        dashRoutes.push({
          path: "/work-experience-form/:childId",
          component: WorkExperienceForm,
          layout: "/admin/consultant-form/:id",
        });
      }
    }
  }

  if (PermissionHelper.checkPermission("view_schedule")) {
    dashRoutes.push(
      {
        path: "/weekly_schedule",
        component: ConsultantSchedulingList,
        layout: "/consultant",
      },
      {
        path: "/daily_schedule",
        component: ConsultantSchedulingDailyList,
        layout: "/consultant",
      },
      {
        path: "/weekly_scheduling",
        component: ConsultantSchedulingList,
        layout: "/admin/consultant-form/:id",
      },
      {
        path: "/daily_scheduling",
        component: ConsultantSchedulingDailyList,
        layout: "/admin/consultant-form/:id",
      }
    );
    if (
      PermissionHelper.checkPermission("add_schedule") ||
      PermissionHelper.checkPermission("edit_schedule")
    ) {
      if (UserHelper.isConsultant()) {
        dashRoutes.push(
          {
            path: "/consultant-scheduling-form/:childId",
            component: ConsultantSchedulingForm,
            layout: "/consultant",
          },
          {
            path: "/consultant-scheduling-daily-form/:childId",
            component: ConsultantSchedulingDailyForm,
            layout: "/consultant",
          }
        );
      } else {
        dashRoutes.push(
          {
            path: "/consultant-scheduling-form/:childId",
            component: ConsultantSchedulingForm,
            layout: "/admin/consultant-form/:id",
          },
          {
            path: "/consultant-scheduling-daily-form/:childId",
            component: ConsultantSchedulingDailyForm,
            layout: "/admin/consultant-form/:id",
          }
        );
      }
    }
  }
  if (PermissionHelper.checkPermission("view_booking")) {
    dashRoutes.push(
      {
        path: "/past_booking",
        component: PastBookingList,
        layout: "/consultant",
      },
      {
        path: "/upcoming_booking",
        component: UpcomingBookingList,
        layout: "/consultant",
      },
      {
        path: "/past-booking",
        component: PastBookingList,
        layout: "/admin/consultant-form/:id",
      },
      {
        path: "/past_booking",
        component: PastBookingList,
        layout: "/admin",
      },

      {
        path: "/upcoming-booking",
        component: UpcomingBookingList,
        layout: "/admin/consultant-form/:id",
      },
      {
        path: "/upcoming_booking",
        component: UpcomingBookingList,
        layout: "/admin",
      }
    );
    if (PermissionHelper.checkPermission("view_booking_logs")) {
      dashRoutes.push({
        path: "/booking_logs",
        component: BookingLogsList,
        layout: "/admin",
      });
    }
    if (PermissionHelper.checkPermission("view_booking_transactions")) {
      dashRoutes.push({
        path: "/booking_transaction",
        component: BookingTransaction,
        layout: "/admin",
      });
    }

    if (
      PermissionHelper.checkPermission("add_booking") ||
      PermissionHelper.checkPermission("edit_booking")
    ) {
      if (UserHelper.isConsultant()) {
        dashRoutes.push({
          path: "/upcoming-booking-form/:childId",
          component: UpcomingBookingForm,
          layout: "/consultant",
        });
      } else {
        dashRoutes.push({
          path: "/upcoming-booking-form/:childId",
          component: UpcomingBookingForm,
          layout: "/admin",
        });
        dashRoutes.push({
          path: "/upcoming-booking-form/:childId",
          component: UpcomingBookingForm,
          layout: "/admin/consultant-form/:id",
        });
      }
    }
  }
  if (PermissionHelper.checkPermission("view_rate_card")) {
    dashRoutes.push(
      {
        path: "/rate_card",
        component: RateCardList,
        layout: "/consultant",
      },
      {
        path: "/rate-card",
        component: RateCardList,
        layout: "/admin/consultant-form/:id",
      }
    );
    if (
      PermissionHelper.checkPermission("add_rate_card") ||
      PermissionHelper.checkPermission("edit_rate_card")
    ) {
      if (UserHelper.isConsultant()) {
        dashRoutes.push({
          path: "/rate-card-form/:childId",
          component: RateCardForm,
          layout: "/consultant",
        });
      } else {
        dashRoutes.push({
          path: "/rate-card-form/:childId",
          component: RateCardForm,
          layout: "/admin/consultant-form/:id",
        });
      }
    }
  }
  if (PermissionHelper.checkPermission("view_complaints")) {
    dashRoutes.push(
      {
        path: "/complaints",
        component: ComplaintsList,
        layout: "/admin",
      },
      {
        path: "/complaints",
        component: ComplaintsList,
        layout: "/admin/consultant-form/:id",
      }
    );
    if (
      PermissionHelper.checkPermission("add_complaints") ||
      PermissionHelper.checkPermission("edit_complaints")
    ) {
      dashRoutes.push();
    }
  }
  if (PermissionHelper.checkPermission("view_reviews")) {
    dashRoutes.push(
      dashRoutes.push(
        {
          path: "/reviews",
          component: ReviewsList,
          layout: "/admin",
        },
        {
          path: "/reviews",
          component: ReviewsList,
          layout: "/admin/consultant-form/:id",
        }
      )
    );
    if (
      PermissionHelper.checkPermission("add_reviews") ||
      PermissionHelper.checkPermission("edit_reviews")
    ) {
      dashRoutes.push({
        path: "/reviews-form/:id",
        component: ReviewsForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_consultant_payout")) {
    dashRoutes.push(
      {
        path: "/payout",
        component: ConsultantPayoutHistoryList,
        layout: "/consultant",
      },
      {
        path: "/consultant-payout",
        component: ConsultantPayoutHistoryList,
        layout: "/admin/consultant-form/:id",
      },
      {
        path: "/consultant_payout",
        component: ConsultantPayoutHistoryList,
        layout: "/admin",
      }
    );
    if (
      PermissionHelper.checkPermission("add_consultant_payout") ||
      PermissionHelper.checkPermission("edit_consultant_payout")
    ) {
      if (UserHelper.isConsultant()) {
        dashRoutes.push({
          path: "/consultant-payout-form/:childId",
          component: ConsultantPayoutHistoryForm,
          layout: "/consultant",
        });
      } else {
        dashRoutes.push({
          path: "/consultant-payout-form/:childId",
          component: ConsultantPayoutHistoryForm,
          layout: "/admin",
        });
        dashRoutes.push({
          path: "/consultant-payout-form/:childId",
          component: ConsultantPayoutHistoryForm,
          layout: "/admin/consultant-form/:id",
        });
      }
    }
  }
  if (PermissionHelper.checkPermission("view_chat_history")) {
    dashRoutes.push(
      {
        path: "/chat-history",
        component: ChatHistoryList,
        layout: "/consultant",
      },
      {
        path: "/chat-history",
        component: ChatHistoryList,
        layout: "/admin/consultant-form/:id",
      },
      {
        path: "/chat-history",
        component: ChatHistoryList,
        layout: "/admin",
      }
    );
    if (PermissionHelper.checkPermission("view_chat_logs")) {
      // dashRoutes.push({
      //   path: "/chat-log-view/:id",
      //   component: VisitorChatLogView,
      //   layout: "/admin/consultant-form/:id",
      // });

      if (UserHelper.isConsultant()) {
        dashRoutes.push({
          path: "/chat-history-form/:childId",
          component: ChatHistoryForm,
          layout: "/consultant",
        });
      } else {
        dashRoutes.push({
          path: "/chat-history-form/:childId",
          component: ChatHistoryForm,
          layout: "/admin",
        });
        dashRoutes.push({
          path: "/chat-history-form/:childId",
          component: ChatHistoryForm,
          layout: "/admin/consultant-form/:id",
        });
      }
    }
  }
  if (PermissionHelper.checkPermission("view_refund_request")) {
    dashRoutes.push(
      {
        path: "/refund_request",
        component: RefundRequestList,
        layout: "/consultant",
      },
      {
        path: "/refund-request",
        component: RefundRequestList,
        layout: "/admin/consultant-form/:id",
      },
      {
        path: "/refund_request",
        component: RefundRequestList,
        layout: "/admin",
      }
    );
    if (
      PermissionHelper.checkPermission("add_refund_request") ||
      PermissionHelper.checkPermission("edit_refund_request")
    ) {
      if (UserHelper.isConsultant()) {
        dashRoutes.push({
          path: "/refund-request-form/:childId",
          component: RefundRequestForm,
          layout: "/consultant",
        });
      } else {
        dashRoutes.push({
          path: "/refund-request-form/:childId",
          component: RefundRequestForm,
          layout: "/admin",
        });
        dashRoutes.push({
          path: "/refund-request-form/:childId",
          component: RefundRequestForm,
          layout: "/admin/consultant-form/:id",
        });
      }
    }
  }
  if (PermissionHelper.checkPermission("view_credit_purchase")) {
    dashRoutes.push(
      {
        path: "/visitor_credit_history",
        component: VisitorCreditHistoryList,
        layout: "/admin",
      },
      {
        path: "/visitor_credit_purchase",
        component: VisitorCreditPurchaseList,
        layout: "/admin",
      }
    );
    if (
      PermissionHelper.checkPermission("add_credit_purchase") ||
      PermissionHelper.checkPermission("edit_credit_purchase")
    ) {
      // dashRoutes.push({
      //   path: "/visitor-credit-history-form/:id",
      //   component: VisitorCreditHistoryForm,
      //   layout: "/admin",
      // });
      dashRoutes.push({
        path: "/visitor-credit-purchase-form/:id",
        component: VisitorCreditPurchaseForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_free_addons")) {
    dashRoutes.push({
      path: "/free_addons",
      component: FreeAddonsList,
      layout: "/admin",
    });
    if (
      PermissionHelper.checkPermission("add_free_addons") ||
      PermissionHelper.checkPermission("edit_free_addons")
    ) {
      dashRoutes.push({
        path: "/free-addons/:childId",
        component: FreeAddonsForm,
        layout: "/admin/consultant-form/:id",
      });
      if (UserHelper.isConsultant()) {
        dashRoutes.push({
          path: "/free-addons-form/:childId",
          component: FreeAddonsForm,
          layout: "/consultant",
        });
      } else {
        dashRoutes.push({
          path: "/free-addons-form/:childId",
          component: FreeAddonsForm,
          layout: "/admin",
        });
      }
    }
  }
  if (PermissionHelper.checkPermission("view_consultant_report")) {
    dashRoutes.push(
      {
        path: "/booking-reports",
        component: BookingReportsList,
        layout: "/admin",
      },
      {
        path: "/consultant-reports",
        component: ConsultantReportsList,
        layout: "/admin",
      },
      {
        path: "/consultant-payout-reports",
        component: ConsultantPayoutReportsList,
        layout: "/admin",
      },
      {
        path: "/consultant-payout-reports",
        component: ConsultantPayoutReportsList,
        layout: "/consultant",
      },
      {
        path: "/visitor-reports",
        component: VisitorReportsList,
        layout: "/admin",
      },
      {
        path: "/visitor-credit-reports",
        component: VisitorCreditReportsList,
        layout: "/admin",
      },
      {
        path: "/visitor-credit-purchase-reports",
        component: VisitorCreditPurchaseReportsList,
        layout: "/admin",
      },
      {
        path: "/booking-reports",
        component: BookingReportsList,
        layout: "/consultant",
      }
    );
  }
  if (PermissionHelper.checkPermission("view_consultant_report")) {
    dashRoutes.push(
      {
        path: "/reports",
        component: ReportsList,
        layout: "/consultant",
      },
      {
        path: "/reports/:childId",
        component: ReportsForm,
        layout: "/admin/consultant-form/:id",
      }
    );
  }
}
//Service routes
if (PermissionHelper.checkPermission("view_service_manager")) {
  dashRoutes.push({
    path: "/dashboard",
    component: ConsultantDashboard,
    layout: "/consultant",
  });
  if (PermissionHelper.checkPermission("view_services")) {
    dashRoutes.push(
      dashRoutes.push({
        path: "/service",
        component: ServiceList,
        layout: "/admin",
      })
    );
  }
  if (
    PermissionHelper.checkPermission("add_services") ||
    PermissionHelper.checkPermission("edit_services")
  ) {
    dashRoutes.push(
      {
        path: "/service-form/:id",
        component: ServiceTabForm,
        layout: "/admin",
      },
      {
        path: "/basic-info",
        component: ServiceForm,
        layout: "/admin/service-form/:id",
      }
    );
  }
  if (PermissionHelper.checkPermission("view_service_request")) {
    dashRoutes.push(
      {
        path: "/request",
        component: RequestList,
        layout: "/admin/service-form/:id",
      },
      {
        path: "/project-request",
        component: RequestList,
        layout: "/admin",
      }
    );
    if (
      PermissionHelper.checkPermission("add_service_request") ||
      PermissionHelper.checkPermission("edit_service_request")
    ) {
      dashRoutes.push(
        {
          path: "/service-request-form/:childId",
          component: RequestForm,
          layout: "/admin/service-form/:id",
        },
        {
          path: "/service-request-form/:id",
          component: RequestForm,
          layout: "/admin",
        }
      );
    }
  }

  // scoping call route
  if (PermissionHelper.checkPermission("view_service_request")) {
    dashRoutes.push({
      path: "/scoping-call",
      component: ScopingCallList,
      layout: "/admin",
    });
    if (
      PermissionHelper.checkPermission("add_service_request") ||
      PermissionHelper.checkPermission("edit_service_request")
    ) {
      dashRoutes.push({
        path: "/scoping-call-form/:id",
        component: ScopingCallForm,
        layout: "/admin",
      });
    }
  }
}

// User Profile
if (UserHelper.isConsultant()) {
  dashRoutes.push({
    path: "/profile-form",
    component: ConsultantProfileForm,
    layout: "/consultant",
  });
} else {
  dashRoutes.push({
    path: "/profile-form",
    component: ProfileForm,
    layout: "/admin",
  });
}

// User Manager
if (PermissionHelper.checkPermission("view_user_manager")) {
  if (PermissionHelper.checkPermission("view_permission_group")) {
    dashRoutes.push({
      path: "/permission_groups",
      component: PermissionGroupList,
      layout: "/admin",
    });
    if (PermissionHelper.checkPermission("edit_permission_group")) {
      dashRoutes.push({
        path: "/permission-group-form/:id",
        component: PermissionGroupForm,
        layout: "/admin",
      });
    }
  }

  if (PermissionHelper.checkPermission("view_permission")) {
    dashRoutes.push({
      path: "/permissions",
      component: PermissionList,
      layout: "/admin",
    });
    if (PermissionHelper.checkPermission("edit_permission")) {
      dashRoutes.push({
        path: "/permission-form/:id",
        component: PermissionForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_roles")) {
    dashRoutes.push({
      path: "/roles",
      component: RolesList,
      layout: "/admin",
    });
    if (
      PermissionHelper.checkPermission("add_roles") ||
      PermissionHelper.checkPermission("edit_roles")
    ) {
      dashRoutes.push({
        path: "/role-form/:id",
        component: RoleForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_users")) {
    dashRoutes.push({
      path: "/users",
      component: UsersList,
      layout: "/admin",
    });
    if (
      PermissionHelper.checkPermission("add_users") ||
      PermissionHelper.checkPermission("edit_users")
    ) {
      dashRoutes.push({
        path: "/user-form/:id",
        component: UserForm,
        layout: "/admin",
      });
    }
  }
}

// Master Manager
if (PermissionHelper.checkPermission("view_master_manager")) {
  if (PermissionHelper.checkPermission("view_country_groups")) {
    dashRoutes.push({
      path: "/country_groups",
      component: CountryGroupList,
      layout: "/admin",
    });

    if (
      PermissionHelper.checkPermission("add_country_groups") ||
      PermissionHelper.checkPermission("edit_country_groups")
    ) {
      dashRoutes.push({
        path: "/country-group-form/:id",
        component: CountryGroupForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_countries")) {
    dashRoutes.push({
      path: "/countries",
      component: CountriesList,
      layout: "/admin",
    });

    dashRoutes.push({
      path: "/timezone",
      component: TimeZoneList,
      layout: "/admin",
    });
    if (
      PermissionHelper.checkPermission("add_countries") ||
      PermissionHelper.checkPermission("edit_countries")
    ) {
      dashRoutes.push({
        path: "/country-form/:id",
        component: CountryForm,
        layout: "/admin",
      });
      dashRoutes.push({
        path: "/timezone-form/:id",
        component: TimeZoneForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_skills")) {
    dashRoutes.push({
      path: "/skills",
      component: SkillsList,
      layout: "/admin",
    });

    if (
      PermissionHelper.checkPermission("add_skills") ||
      PermissionHelper.checkPermission("edit_skills")
    ) {
      dashRoutes.push({
        path: "/skills-form/:id",
        component: SkillsForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_pages")) {
    dashRoutes.push({
      path: "/pages",
      component: PageList,
      layout: "/admin",
    });

    if (PermissionHelper.checkPermission("edit_pages")) {
      dashRoutes.push({
        path: "/page-form/:id",
        component: PageForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_rss")) {
    dashRoutes.push({
      path: "/rss",
      component: RssList,
      layout: "/admin",
    });

    if (
      PermissionHelper.checkPermission("add_rss") ||
      PermissionHelper.checkPermission("edit_rss")
    ) {
      dashRoutes.push({
        path: "/rss-form/:id",
        component: RssForm,
        layout: "/admin",
      });
    }
  }
  if (PermissionHelper.checkPermission("view_currency")) {
    dashRoutes.push({
      path: "/currency",
      component: CurrencyList,
      layout: "/admin",
    });

    if (
      PermissionHelper.checkPermission("add_currency") ||
      PermissionHelper.checkPermission("edit_currency")
    ) {
      dashRoutes.push({
        path: "/currency-form/:id",
        component: CurrencyForm,
        layout: "/admin",
      });
    }
  }

  if (PermissionHelper.checkPermission("view_persona")) {
    dashRoutes.push({
      path: "/persona",
      component: PersonaList,
      layout: "/admin",
    });

    if (
      PermissionHelper.checkPermission("add_persona") ||
      PermissionHelper.checkPermission("edit_persona")
    ) {
      dashRoutes.push({
        path: "/persona-form/:id",
        component: PersonaForm,
        layout: "/admin",
      });
    }
  }
}

dashRoutes.push({
  path: "/search-log",
  component: SearchlogList,
  layout: "/admin/consultant-form/:id",
});
dashRoutes.push({
  path: "/search-log",
  component: SearchlogList,
  layout: "/admin",
});

if (true) {
  // FAQ Manager
  if (PermissionHelper.checkPermission("view_faq_manager")) {
    if (PermissionHelper.checkPermission("view_faq_category")) {
      dashRoutes.push({
        path: "/FAQ-Category",
        component: FAQ,
        layout: "/admin",
      });

      if (
        PermissionHelper.checkPermission("add_faq_category") ||
        PermissionHelper.checkPermission("edit_faq_category")
      ) {
        dashRoutes.push({
          path: "/FAQ-Category-form/:id",
          component: FAQForm,
          layout: "/admin",
        });
      }
    }

    if (PermissionHelper.checkPermission("view_faq_list")) {
      dashRoutes.push({
        path: "/FAQ-list",
        component: FAQS,
        layout: "/admin",
      });

      if (
        PermissionHelper.checkPermission("add_faq_list") ||
        PermissionHelper.checkPermission("edit_faq_list")
      ) {
        dashRoutes.push({
          path: "/FAQ-list-form/:id",
          component: FAQs,
          layout: "/admin",
        });
      }
    }
  }

  // Blog Manager
  // if (PermissionHelper.checkPermission("View_blog_manager")) {
  //   dashRoutes.push(
  //     {
  //       path: "/Blog-List",
  //       component: BlogLists,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/Blog-Category",
  //       component: BlogCategory,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/Blog-Category-form/:id",
  //       component: BlogCategoryForm,
  //       layout: "/admin",
  //     }
  //   );

  //   if (
  //     PermissionHelper.checkPermission("add_blog_manager") ||
  //     PermissionHelper.checkPermission("edit_blog_manager")
  //   ) {
  //     dashRoutes.push({
  //       path: "/Blog-List-form/:id",
  //       component: BlogListForm,
  //       layout: "/admin",
  //     });
  //   }
  // }

  // Visitor Manager
  if (PermissionHelper.checkPermission("view_visitor_manager")) {
    if (PermissionHelper.checkPermission("view_visitors")) {
      dashRoutes.push(
        {
          path: "/visitors",
          component: VisitorList,
          layout: "/admin",
        },
        {
          path: "/guestvisitors",
          component: GuestList,
          layout: "/admin",
        }
      );

      if (
        PermissionHelper.checkPermission("add_visitors") ||
        PermissionHelper.checkPermission("edit_visitors")
      ) {
        dashRoutes.push({
          path: "/visitor-form/:id",
          component: VisitorForm,
          layout: "/admin",
        });
      }
    }

    if (PermissionHelper.checkPermission("view_visitor_group")) {
      dashRoutes.push({
        path: "/visitor_groups",
        component: VisitorGroupList,
        layout: "/admin",
      });

      if (
        PermissionHelper.checkPermission("add_visitor_groups") ||
        PermissionHelper.checkPermission("edit_visitor_groups")
      ) {
        dashRoutes.push({
          path: "/visitor-group-form/:id",
          component: VisitorGroupForm,
          layout: "/admin",
        });
      }
    }
  }

  // Report Manager
  if (PermissionHelper.checkPermission("view_visitor_report_manager")) {
    if (PermissionHelper.checkPermission("view_visitor_ip_logs")) {
      dashRoutes.push({
        path: "/ip_logs",
        component: IPLogList,
        layout: "/admin",
      });
    }
    if (PermissionHelper.checkPermission("view_visitor_login_logs")) {
      dashRoutes.push({
        path: "/login_logs",
        component: VisitorLoginLogList,
        layout: "/admin",
      });
    }
  }
}

// Setting Manager
if (PermissionHelper.checkMainPermission(["view_setting_manager"])) {
  if (PermissionHelper.checkMainPermission(["view_cron_manager"])) {
    dashRoutes.push({
      path: "/cron-jobs",
      component: CronList,
      layout: "/admin",
    });
    if (
      PermissionHelper.checkMainPermission(["add_cron_job"]) ||
      PermissionHelper.checkMainPermission(["edit_cron_job"])
    ) {
      dashRoutes.push({
        path: "/cron-jobs-form/:id",
        component: CronForm,
        layout: "/admin",
      });
    }
  }

  dashRoutes.push(
    {
      path: "/config",
      component: ConfigList,
      layout: "/admin",
    },
    {
      path: "/config-form/:id",
      component: ConfigForm,
      layout: "/admin",
    }
  );
}

// Log Manager
if (PermissionHelper.checkMainPermission(["view_logs_manager"])) {
  if (PermissionHelper.checkMainPermission(["view_cron_logs"])) {
    dashRoutes.push({
      path: "/cron-logs",
      component: CronLogsList,
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_api_logs"])) {
    dashRoutes.push({
      path: "/api_logs",
      component: APILogList,
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_nubela_logs"])) {
    dashRoutes.push({
      path: "/nubela_logs",
      component: NubelaLogList,
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_signal_hire_logs"])) {
    dashRoutes.push({
      path: "/signal_logs",
      component: SignalHireLogList,
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_anomaly_logs"])) {
    dashRoutes.push(
      {
        path: "/anomaly",
        component: AnomalyList,
        layout: "/admin",
      },
      {
        path: "/anomaly-form/:id",
        component: AnomalyForm,
        layout: "/admin",
      }
    );
  }
}

export default dashRoutes;
