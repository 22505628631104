import React from "react";
import { connect } from "react-redux";

import MaterialDataTable from "material-table/Table.js";
import { crudActions, confirmActions } from "../../_actions";

// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { AnalyticsDashboard } from "react-analytics-charts";
// Over ten different commonly used charts are available
import { SessionsByDateChart, SessionsGeoChart } from "react-analytics-charts";

class Dashboard extends React.PureComponent {
  componentDidMount() {
    if (localStorage.getItem("user")) {
      this.props.getAllCrud("dashboard", "dashboard_data");
    }
  }

  render() {
    const { classes, dashboardData } = this.props;

    if (localStorage.getItem("user")) {
      return (
        <div>
          <GridContainer>
            {dashboardData?.map((data) => {
              return (
                <GridItem key={data.name} xs={12} sm={6} md={6} lg={4}>
                  <Card>
                    <CardHeader color={data.color} stats icon>
                      <CardIcon color={data.color}>
                        <Icon>{data.icon}</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>{data.name}</p>
                      <h3 className={classes.cardTitle}>{data.value}</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
              );
            })}
          </GridContainer>

          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="success" icon>
                  <CardIcon color="success">
                    <Icon>view_module</Icon>
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Analytics</h4>
                </CardHeader>
                <CardBody>
                  <AnalyticsDashboard
                    authOptions={{
                      clientId:
                        "230185733028-b9qah4pa0sckbn6rgn9ld591meqbgi50.apps.googleusercontent.com",
                    }}
                    renderCharts={(gapi, viewId) => {
                      return (
                        <div>
                          <SessionsByDateChart
                            gapi={gapi}
                            viewId={viewId}
                            showPageViews
                            showUsers
                          />

                          <SessionsGeoChart
                            gapi={gapi}
                            viewId={viewId}
                            showPageViews
                          />
                        </div>
                      );
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => {
  const { authentication, confirm, dashboard } = state;
  const { user } = authentication;
  return { user, confirm, dashboardData: dashboard };
};

const actionCreators = {
  getAllCrud: crudActions._getAll,
  deleteCrud: crudActions._delete,
  showConfirm: confirmActions.show,
  clearConfirm: confirmActions.clear,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    actionCreators
  )(Dashboard)
);
