export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    FORGOT_REQUEST: 'USERS_FORGOT_REQUEST',
    FORGOT_SUCCESS: 'USERS_FORGOT_SUCCESS',
    FORGOT_FAILURE: 'USERS_FORGOT_FAILURE',

    CONFIRM_REQUEST: 'USERS_CONFIRM_REQUEST',
    CONFIRM_SUCCESS: 'USERS_CONFIRM_SUCCESS',
    CONFIRM_FAILURE: 'USERS_CONFIRM_FAILURE',

    LOGOUT: 'USERS_LOGOUT',    
};