export const crudConstants = {
    GET_ALL_REQUEST: 'CRUD_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'CRUD_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CRUD_GET_ALL_FAILURE',

    GET_REQUEST: 'CRUD_GET_REQUEST',
    GET_SUCCESS: 'CRUD_GET_SUCCESS',
    GET_FAILURE: 'CRUD_GET_FAILURE',

    CREATE_REQUEST: 'CRUD_CREATE_REQUEST',
    CREATE_SUCCESS: 'CRUD_CREATE_SUCCESS',
    CREATE_FAILURE: 'CRUD_CREATE_FAILURE',

    UPDATE_REQUEST: 'CRUD_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CRUD_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CRUD_UPDATE_FAILURE',

    DELETE_REQUEST: 'CRUD_DELETE_REQUEST',
    DELETE_SUCCESS: 'CRUD_DELETE_SUCCESS',
    DELETE_FAILURE: 'CRUD_DELETE_FAILURE',

    CLEAR: 'CRUD_CLEAR',
    RESET: 'CRUD_RESET',
};