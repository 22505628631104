import AppsIcon from "@material-ui/icons/Apps";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@material-ui/icons/Dashboard";
import UserIcon from "@material-ui/icons/Person";
import HistoryIcon from "@material-ui/icons/History";
import SettingIcon from "@material-ui/icons/Settings";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import { PermissionHelper } from "_helpers";
var dashRoutes = [];

// Dashboard
dashRoutes.push({
  collapse: true,
  name: "Dashboard",
  rtlName: "",
  icon: DashboardIcon,
  state: "DashboardCollapse",
  views: [
    {
      path: "/dashboard",
      name: "Dashboard",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    },
    {
      path: "/consultant_dashboard",
      name: "Consultant Dashboard",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    },
  ],
});

// User Manager
if (PermissionHelper.checkMainPermission(["view_user_manager"])) {
  let views = [];
  if (PermissionHelper.checkMainPermission(["view_permission_group"])) {
    views.push({
      path: "/permission_groups",
      name: "Permission Group",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_permission"])) {
    views.push({
      path: "/permissions",
      name: "Permissions",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_roles"])) {
    views.push({
      path: "/roles",
      name: "Roles",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_users"])) {
    views.push({
      path: "/users",
      name: "Users",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }

  dashRoutes.push({
    collapse: true,
    name: "User Manager",
    rtlName: "",
    icon: UserIcon,
    state: "userModuleCollapse",
    views: views,
  });
}

// Master Manager
if (PermissionHelper.checkMainPermission(["view_master_manager"])) {
  let views = [];
  if (PermissionHelper.checkMainPermission(["view_country_groups"])) {
    views.push({
      path: "/country_groups",
      name: "Country Groups",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_countries"])) {
    views.push({
      path: "/countries",
      name: "Countries",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
    views.push({
      path: "/timezone",
      name: "Time Zone",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_skills"])) {
    views.push({
      path: "/skills",
      name: "Skills",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_pages"])) {
    views.push({
      path: "/pages",
      name: "Pages",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_rss"])) {
    views.push({
      path: "/rss",
      name: "RSS",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_currency"])) {
    views.push({
      path: "/currency",
      name: "Currency",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }

  if (PermissionHelper.checkMainPermission(["view_persona"])) {
    views.push({
      path: "/persona",
      name: "Persona",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }

  // Master Manager
  dashRoutes.push({
    collapse: true,
    name: "Master Manager",
    rtlName: "",
    icon: AppsIcon,
    state: "MasterModuleCollapse",
    views: views,
  });
}

// Consultant
if (PermissionHelper.checkMainPermission(["view_consultant_manager"])) {
  let views = [];
  if (PermissionHelper.checkMainPermission(["view_consultant"])) {
    views.push({
      path: "/consultant",
      name: "Consultant",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_booking"])) {
    let subViews = [];
    subViews.push(
      // {
      //   path: "/booking",
      //   name: "Booking",
      //   rtlName: "",
      //   mini: "",
      //   rtlMini: "",
      //   layout: "/admin",
      // },
      {
        path: "/upcoming_booking",
        name: "Upcoming Booking",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      },
      {
        path: "/past_booking",
        name: "Past Booking",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      }
    );
    if (PermissionHelper.checkMainPermission(["view_booking_logs"])) {
      subViews.push({
        path: "/booking_logs",
        name: "Booking Logs",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      });
    }
    if (PermissionHelper.checkMainPermission(["view_booking_transactions"])) {
      subViews.push({
        path: "/booking_transaction",
        name: "Booking Transaction",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      });
    }
    if (PermissionHelper.checkMainPermission(["view_booking_transactions"])) {
      subViews.push({
        path: "/refund_request",
        name: "Refund Request",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      });
    }
    views.push({
      collapse: true,
      name: "Booking",
      rtlName: "",
      state: "BookingCollapse",
      views: subViews,
    });
  }
  if (PermissionHelper.checkMainPermission(["view_reviews"])) {
    views.push({
      path: "/reviews",
      name: "Reviews",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_credit_purchase"])) {
    views.push(
      // {
      //   path: "/visitor_credit_history",
      //   name: "Visitor Credit History",
      //   rtlName: "",
      //   mini: "",
      //   rtlMini: "",
      //   layout: "/admin",
      // },
      {
        path: "/visitor_credit_purchase",
        name: "Visitor Credit Purchase",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      }
    );
  }
  if (PermissionHelper.checkMainPermission(["view_chat_history"])) {
    views.push({
      path: "/chat-history",
      name: "Chat History",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_free_addons"])) {
    views.push({
      path: "/free_addons",
      name: "Free Addons for Booking",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_consultant_payout"])) {
    views.push({
      path: "/consultant_payout",
      name: "Consultant Payout History",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }

  if (PermissionHelper.checkMainPermission(["view_complaints"])) {
    views.push({
      path: "/search-log",
      name: "Search Log",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_complaints"])) {
    views.push({
      path: "/complaints",
      name: "Complaints",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  dashRoutes.push({
    collapse: true,
    name: "Consultant",
    rtlName: "",
    icon: PeopleIcon,
    state: "ConsultantCollapse",
    views: views,
  });
  if (PermissionHelper.checkMainPermission(["view_booking"])) {
    let subViews = [];
    subViews.push(
      {
        path: "/booking-reports",
        name: "Booking Reports",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      },
      {
        path: "/consultant-reports",
        name: "Consultant Reports",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      },
      {
        path: "/consultant-payout-reports",
        name: "Consultant Payout Reports",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      }
    );

    if (PermissionHelper.checkMainPermission(["view_booking_transactions"])) {
      subViews.push({
        path: "/visitor-credit-reports",
        name: "Visitor Credit Reports",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      });
    }
    if (PermissionHelper.checkMainPermission(["view_booking_transactions"])) {
      subViews.push({
        path: "/visitor-credit-purchase-reports",
        name: "Visitor Credit Purchase Report",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      });
    }
    views.push({
      collapse: true,
      name: "Reports",
      rtlName: "",
      state: "ReportsCollapse",
      views: subViews,
    });
  }
}
// Service Module
if (PermissionHelper.checkMainPermission(["view_service_manager"])) {
  let views = [];
  if (PermissionHelper.checkMainPermission(["view_services"])) {
    views.push({
      path: "/service",
      name: "Service",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }

  if (PermissionHelper.checkMainPermission(["view_service_request"])) {
    views.push({
      path: "/project-request",
      name: "Request",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }

  if (PermissionHelper.checkMainPermission(["view_service_request"])) {
    views.push({
      path: "/scoping-call",
      name: "Scoping Call",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  dashRoutes.push({
    collapse: true,
    name: "Service",
    rtlName: "",
    icon: PeopleIcon,
    state: "ServiceCollapse",
    views: views,
  });
}

//End User
{
  let views = [];
  if (true) {
    // FAQ Module

    if (PermissionHelper.checkMainPermission(["view_faq_manager"])) {
      let subViews = [];
      if (PermissionHelper.checkMainPermission(["view_faq_category"])) {
        subViews.push({
          path: "/FAQ-Category",
          name: "FAQ Category",
          rtlName: "",
          mini: "",
          rtlMini: "",
          layout: "/admin",
        });
      }

      if (PermissionHelper.checkMainPermission(["view_faq_list"])) {
        subViews.push({
          path: "/FAQ-list",
          name: "FAQ",
          rtlName: "",
          mini: "",
          rtlMini: "",
          layout: "/admin",
        });
      }
    }

    // Blog Manager

    // if (PermissionHelper.checkMainPermission(["View_blog_manager"])) {
    //   views.push({
    //     collapse: true,
    //     name: "Blog Manager",
    //     rtlName: "",
    //     state: "BlogModuleCollapse",
    //     views: [
    //       {
    //         path: "/Blog-List",
    //         name: "Blog",
    //         rtlName: "",
    //         mini: "",
    //         rtlMini: "",
    //         layout: "/admin",
    //       },
    //     ],
    //   });
    // }

    // Visitor Manager
    if (PermissionHelper.checkMainPermission(["view_visitor_manager"])) {
      let subViews = [];

      if (PermissionHelper.checkMainPermission(["view_visitors"])) {
        subViews.push({
          path: "/visitors",
          name: "Visitors",
          rtlName: "",
          mini: "",
          rtlMini: "",
          layout: "/admin",
        });
        subViews.push({
          path: "/guestvisitors",
          name: "Guest Visitors",
          rtlName: "",
          mini: "",
          rtlMini: "",
          layout: "/admin",
        });
      }

      views.push({
        collapse: true,
        name: "Visitor Manager",
        rtlName: "",
        state: "VisitorModuleCollapse",
        views: subViews,
      });
    }

    // Report Manager
    if (PermissionHelper.checkMainPermission(["view_visitor_report_manager"])) {
      let subViews = [];

      subViews.push({
        path: "/ip_logs",
        name: "Visitor IP Log",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      });

      subViews.push({
        path: "/login_logs",
        name: "Visitor Login Log",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      });

      views.push({
        collapse: true,
        name: "Visitor Report",
        rtlName: "",
        state: "ReportCollapse",
        views: subViews,
      });
    }
    dashRoutes.push({
      collapse: true,
      name: "End User",
      rtlName: "",
      icon: CardMembershipIcon,
      state: "EuSegmentModuleCollapse",
      views: views,
    });
  }
}

// Setting
if (PermissionHelper.checkMainPermission(["view_setting_manager"])) {
  dashRoutes.push({
    collapse: true,
    name: "Setting",
    rtlName: "",
    icon: SettingIcon,
    state: "SettingCollapse",
    views: [
      {
        path: "/cron-jobs",
        name: "Cron Jobs",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      },
      {
        path: "/config",
        name: "Config",
        rtlName: "",
        mini: "",
        rtlMini: "",
        layout: "/admin",
      },
    ],
  });
}

// Logs
if (PermissionHelper.checkMainPermission(["view_logs_manager"])) {
  let views = [];
  if (PermissionHelper.checkMainPermission(["view_cron_logs"])) {
    views.push({
      path: "/cron-logs",
      name: "Cron Logs",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_api_logs"])) {
    views.push({
      path: "/api_logs",
      name: "API Logs",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_nubela_logs"])) {
    views.push({
      path: "/nubela_logs",
      name: "Nubela Logs",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_signal_hire_logs"])) {
    views.push({
      path: "/signal_logs",
      name: "Signal Hire Logs",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }
  if (PermissionHelper.checkMainPermission(["view_anomaly_logs"])) {
    views.push({
      path: "/anomaly",
      name: "Anomaly",
      rtlName: "",
      mini: "",
      rtlMini: "",
      layout: "/admin",
    });
  }

  dashRoutes.push({
    collapse: true,
    name: "Logs",
    rtlName: "",
    icon: HistoryIcon,
    state: "LogsCollapse",
    views: views,
  });
}

export default dashRoutes;
